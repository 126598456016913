.body {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
}

.main {
    min-height: 100vh;
    display: flex;
    flex-grow: 1;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    flex-direction: column;
}