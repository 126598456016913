.servicos {
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: -50px;
    color: var(--main-logo-blue-high-opcty);
}

.servicos h2 {
    font-weight: bold;
}

.instalacoes {
    position: relative;
    margin-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    color: var(--main-logo-blue-high-opcty);
}

.instalacoes h2 {
    font-weight: bold;
}

.textsServices {
    margin-bottom: 0;
    height: 40px;
}

.item {
    padding: 0.7rem;
    box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.topicsImage {
    border: solid 2px var(--main-logo-blue-high-opcty);
    color: #41403E;
    font-size: 2rem;
    letter-spacing: 1px;
    padding: 2rem;
    outline: none;
    transition: all .5s ease;
    box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, .2);
    border-radius: 255px 15px 225px 15px/15px 225px
}

.topicsImage:hover {
    box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, .3);
}

.topicsImage img {
    height: 100%;
    width: 100%;
}

@media (min-width: 320px) and (max-width: 992px) {
    .instalacoes {
        position: relative;
        margin-top: 3rem;
        padding-left: 0;
        padding-right: 0;
    }

    .subTitles {
        font-size: 1.1rem;
    }

    .servicos {
        position: relative;
        margin-top: 3rem;
        padding-left: 0;
        padding-right: 0;
    }

    .topicsImage {
        display: none;
    }

    .imageBackgroundOne {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        background: url("/public/servicos_image_lowOpc.jpg") no-repeat right bottom / cover;
    }

    .imageBackgroundTwo {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        background: url("/public/instalacoes_image_opLow.jpg") no-repeat right bottom / cover;
    }
}

.letterSpacingBig {
    letter-spacing: 2px;
}