body {
  margin: 0;
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  --main-bg-coral-low-opcty: rgba(255, 241, 235, 0.4);
  --main-bg-gray-low-opcty: rgba(245, 245, 245, 0.4);
  --main-bg-dark-gray-low-opcty: rgba(234, 234, 235, 1);
  --main-coral-medium-opcty: rgba(255, 167, 130, 0.5);
  --main-gray-medium-opcty: rgba(192, 192, 192, 0.5);
  --main-dark-gray-medium-low-opcty: rgba(183, 183, 186, 0.5);
  --main-coral-high-opcty: rgba(245, 73, 0, 0.1);
  --main-gray-high-opcty: rgba(122, 122, 122, 0.5);
  --main-dark-gray-high-low-opcty: rgba(99, 99, 103, 0.5);

  --main-dark-yellow-high-opcty: rgba(255, 159, 28, 1);
  --main-dark-yellow-medium-opcty: rgba(255, 159, 28, 0.6);
  --main-dark-yellow-low-opcty: rgba(255, 159, 28, 0.1);

  --main-dark-light-yellow-high-opcty: rgba(255, 191, 105, 1);
  --main-dark-light-yellow-medium-opcty: rgba(255, 191, 105, 0.3);
  --main-dark-light-yellow-low-opcty: rgba(255, 191, 105, 0.1);

  --main-dark-light-blue-high-opcty: rgba(203, 243, 240, 1);
  --main-dark-light-blue-medium-opcty: rgba(203, 243, 240, 0.3);
  --main-dark-light-blue-low-opcty: rgba(203, 243, 240, 0.1);

  --main-dark-light-sea-blue-high-opcty: rgba(46, 196, 182, 1);
  --main-dark-light-sea-blue-medium-opcty: rgba(46, 196, 182, 0.3);
  --main-dark-light-sea-blue-low-opcty: rgba(46, 196, 182, 0.1);

  --main-bg-light-sea-blue-low-opcty: rgba(247, 253, 253, 0.8);

  --main-dark-blue-high-opcty: rgba(2, 48, 71, 1);
  --main-dark-blue-large-opcty: rgba(2, 48, 71, 0.8);
  --main-dark-blue-ML-opcty: rgba(2, 48, 71, 0.7);
  --main-dark-blue-medium-opcty: rgba(2, 48, 71, 0.3);
  --main-dark-blue-low-opcty: rgba(2, 48, 71, 0.1);

  --main-logo-blue-high-opcty: rgba(12, 82, 122, 1);
  --main-logo-blue-medium-opcty: rgba(12, 82, 122, 0.7);
  --main-logo-blue-low-opcty: rgba(12, 82, 122, 0.3);

  --logo-yellow: rgba(255, 167, 0, 1);
  --logo-blue: rgba(0, 137, 212, 1);

  --gradient-blue-1: rgba(227, 242, 253, 0.85);
  --gradient-blue-2: rgba(187, 222, 251, 0.85);
  --gradient-blue-3: rgba(144, 202, 249, 0.85);
  --gradient-blue-4: rgba(66, 165, 245, 0.85);
  --gradient-blue-5: rgba(33, 150, 243, 0.85);
  --gradient-blue-6: rgba(25, 118, 210, 0.85);
  --gradient-blue-4-opaque: rgba(66, 165, 245, 1);
  --gradient-blue-5-opaque: rgba(33, 150, 243, 1);
  --gradient-blue-6-opaque: rgba(25, 118, 210, 1);
  --gradient-yellow-1: rgba(255, 167, 0, 0.1);
  --gradient-yellow-1-opaque: rgba(255, 167, 0, 0.7);

}

.dark-blue-text {
  color: var(--main-logo-blue-high-opcty);
}

.dark-yellow-text {
  color: var(--logo-yellow);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

div {
  display: flex;
  flex-direction: column;
  border: 0 solid #000;
  padding: 0;
  flex-shrink: 0;
}

button {
  border-width: 0;
  border-style: none;
  border-image: none;
  border-color: black;
  text-decoration: none;
}

input::selection {
  background-color: var(--main-dark-light-sea-blue-medium-opcty);
}

.navLinks {
  text-decoration: none;
}