.navBarStyle {
    font-family: 'Bree Serif', sans-serif;
    justify-content: center;
    background-color: transparent;
    text-transform: uppercase;
    color: var(--main-dark-blue-large-opcty);
    transition: border-bottom 0.1s ease-in-out, background 0.1s ease-in-out, padding 0.1s ease-in-out;
}

.logoSize {
    height: 100px;
    width: 235px;
}

.affix {
    background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.8),
            var(--gradient-blue-2),
            var(--gradient-blue-4-opaque),
            var(--gradient-blue-6-opaque)) !important;
}

@media (min-width: 320px) and (max-width: 992px) {
    .navBarStyle {
        background: white;
        padding: 0.95rem 1rem;
    }

    .affix {
        background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0.8),
                var(--gradient-blue-2)) !important;
    }

    .logoSize {
        height: 80px;
        width: 190px;
    }
}

.navBarStyle a {
    text-decoration: none;
    color: var(--main-dark-blue-large-opcty);
    cursor: pointer;
}

.navBarStyle a:hover,
.navBarStyle a:active,
.navBarStyle a.active {
    color: var(--main-dark-blue-medium-opcty);
}

.limitNavWidth {
    width: 90%;
}

.dropdownNav > li > a:hover {
    background-image: none;
    background-color: transparent;
}

.lockImg > a:hover {
    background-image: none;
    background-color: transparent;
}

.dropdownNav li {

}

.logoImg {
    display: flex;
}

.lockImg {
    flex-direction: row;
    align-items: center;
    padding-right: 1rem;
}

.lockImg div {
    background-repeat: no-repeat;
    height: 38px;
    width: 38px;
    background-image: url("/public/imageInstagram.png");
    background-position: -320px -268px;
}

.homeImg img {
    height: 35px;
    width: 35px;
}

.profileImg img {
    height: 42px;
    width: 42px;
    border-radius: 50%;
}

.imageDiv {
    overflow: hidden;
    border-radius: 50%;
    max-height: inherit;
    max-width: inherit;
    position: relative;
    height: 50px !important;
    width: 50px !important;
}

.mainImageDiv {
    border-radius: 50%;
    display: flex;
    position: relative;
}

.imageInsideDropDown {
    padding: 0.25rem 1rem;
}

.showOnMobile {
    display: block;
}

@media (min-width: 500px) {
    .showOnMobile {
        display: none;
    }
}
