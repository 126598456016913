.section {
    background: url("/public/mainImage.png") no-repeat right bottom / contain;
    position: relative;
    height: 800px;
    max-height: 800px;
    min-height: 800px;
}

.articlePresent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 140px;
    width: 75%;
}

.backImg {
    position: relative;
    width: 100%;
    display: block;
}

.backImg::before {
    position: absolute;
    content: "";
    background-image: linear-gradient(
            to right,
            var(--gradient-blue-1),
            var(--gradient-blue-2),
            var(--gradient-blue-3),
            var(--gradient-blue-4));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.imageAbsolut {
    width: 100%;
    position: absolute;
    height: inherit;
    max-height: 800px;
    min-height: 800px;
}

.linkToInscricao {
    display: flex;
    flex-direction: column;
    width: 75%;
}


.sketchy {
    font-family: 'Roboto Slab', sans-serif;
    background-color: var(--gradient-yellow-1);
    color: var(--main-logo-blue-high-opcty);
    text-align: center;
    width: 87%;
    margin-top: 2rem;
    padding: 2rem 2rem;
    display: inline-block;
    border: 3px solid var(--main-logo-blue-high-opcty);
    font-size: 1.5rem;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    position: relative;
    transition: all .5s ease;
    box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, .2);
    cursor: pointer;
}

.sketchy:hover {
    box-shadow: 2px 8px 4px -6px hsla(0, 0%, 0%, .3);
}

.sketchy::before {
    content: '';
    border: 2px solid var(--main-logo-blue-high-opcty);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}

.mensagem {
    font-family: "Bree Serif", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    right: 5%;
    margin-top: 2rem;
    /*position: absolute;
    padding-top: -97px;
    top: 143px;
    */
    width: 75%;
    color: var(--gradient-yellow-1-opaque);
}

.hideOnMobile {
    font-size: 1px;
}

@media (min-width: 320px) and (max-width: 880px) {
    .section {
        background: url("/public/mainImage.png") center center / cover no-repeat;
        position: relative;
        height: 400px;
        max-height: 600px;
        min-height: 600px;
    }

    .imageAbsolut {
        width: 100%;
        position: absolute;
        height: 600px;
        max-height: 600px;
        min-height: 600px;
    }

    .imageFluid {
        display: none;
    }

    .articlePresent {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding-top: 70px;
    }

    .linkToInscricao {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sketchy {
        background-color: var(--gradient-yellow-1-opaque);
        font-size: 1.3rem;
        padding: 1rem;
        width: 100%;
    }

    .mensagem {
        top: 525px;
        color: var(--gradient-yellow-1-opaque);
        width: 100%;
        margin-top: 3rem;
        align-items: center;
        text-shadow: 1px 0 var(--main-logo-blue-high-opcty), 0 -1px var(--main-logo-blue-high-opcty);
    }

    .location {
        color: var(--gradient-yellow-1-opaque);
        width: 100%;
        align-items: center;
        letter-spacing: 2px;
        text-shadow: 1px 0 var(--main-logo-blue-high-opcty), 0 -1px var(--main-logo-blue-high-opcty);
    }

    .mensagem h3 {
        font-size: 1.2rem;
    }

    .hideOnMobile {
        display: none;
    }
}

.imageFluid {
    max-width: 100%;
    height: inherit;
    object-fit: cover;
}

.myContainer {
    display: flex;
    align-items: center;
    position: absolute;
    font-family: 'Bree Serif', sans-serif;
    padding-top: 120px;
    margin-right: auto;
}