.rights {
    flex-direction: row;
    justify-content: center;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    font-size: 0.8rem;
}

.backFooter {
    background-image: linear-gradient(
            to bottom,
            var(--gradient-blue-3),
            var(--gradient-blue-4),
            var(--gradient-blue-4-opaque),
            var(--gradient-blue-6-opaque)) !important;
    color: white;
    margin-top: 5rem;
    padding-top: 2rem;
    box-shadow: 0 3px 3px 2px var(--main-dark-gray-high-low-opcty);
}

.logosSection {
    max-height: 200px;
}

.logosSection div {
    width: fit-content;
}

.logosSection a {
    width: fit-content;
}

.logo {
    max-height: 100px;
    max-width: 200px;
}